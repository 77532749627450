// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-job-detail-js": () => import("./../../../src/components/jobDetail.js" /* webpackChunkName: "component---src-components-job-detail-js" */),
  "component---src-components-workshop-detail-js": () => import("./../../../src/components/workshopDetail.js" /* webpackChunkName: "component---src-components-workshop-detail-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bvb-js": () => import("./../../../src/pages/bvb.js" /* webpackChunkName: "component---src-pages-bvb-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-lt-fan-platform-early-adopters-club-js": () => import("./../../../src/pages/lt-fan-platform/early-adopters-club.js" /* webpackChunkName: "component---src-pages-lt-fan-platform-early-adopters-club-js" */),
  "component---src-pages-lt-fan-platform-index-js": () => import("./../../../src/pages/lt-fan-platform/index.js" /* webpackChunkName: "component---src-pages-lt-fan-platform-index-js" */),
  "component---src-pages-lt-sports-investment-platform-js": () => import("./../../../src/pages/lt-sports-investment-platform.js" /* webpackChunkName: "component---src-pages-lt-sports-investment-platform-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

